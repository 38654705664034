import React, { useEffect, useState } from "react";
import { useConstructor } from '../../Utils';
import { host } from "../../Utils";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Documentos from "../../components/Documentos";

export function RevistaValorPublico(props) {
  useConstructor(() => props.setTitle(
    "Revista Valor Público"
  ));
    const [numPages, setNumpages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [innerSize, setInnerSize] = useState(0);

    useEffect(() => {
        let widthSize = window.innerWidth;
        console.log(widthSize);
        setInnerSize(widthSize);
    }, [setInnerSize]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumpages(numPages);
    }


    let prevPage = () => {
        
        if (pageNumber !== 2) { setPageNumber(pageNumber - 2) }
        else { setPageNumber(pageNumber - 1)}
    }

    let nextPage = () => {
        let val = 0;

        if ((pageNumber === 1) || (pageNumber + 1 > numPages))
            val = 1;
        else
            val = 2;

        setPageNumber(pageNumber + val)
    }


  useEffect(() => {
    
  }, []);  
  
    return (
        <div>

            <div className="flex-container" style={{ justifyContent: "center" }}> 
                 <div className="justify-content-center"> 
                     {innerSize >= 480 ? ( 
                         <> 
                             <Document 
                                 renderMode="png" 
                                 className="p-3 border bg-light" 
                                // file={host() + "/Publico/Documento/6446"} //local 
                                file={host() + "/Publico/Documento/6558"} 
                                 onLoadSuccess={onDocumentLoadSuccess} 
                             > 
                                 <Page pageIndex={1} fixed pageNumber={pageNumber}></Page> 
                                 {pageNumber !== 1 ? pageNumber !== numPages ? <Page fixed pageNumber={pageNumber + 1}></Page> : '' : ''} 
                             </Document> 
                             <div className="justify-content-center d-flex btn-pages"> 
                                 <div style={{ marginTop: "10px" }}> 
                                     <button style={pageNumber === 1 ? { display: "none" } : {}} 
                                         onClick={prevPage} 
                                         className="btn-outlined btn-sm btn-page" 
                                     > 
                                         <i className="bi bi-arrow-left"></i> 
                                     </button> 
                                     {pageNumber} / {numPages}{" "} 
                                     <button style={pageNumber === numPages ? { display: "none" } : {}} 
                                         onClick={nextPage} 
                                         className="btn-outlined btn-sm btn-page" 
                                     > 
                                         <i className="bi bi-arrow-right"></i> 
                                     </button> 
                                 </div> 
                             </div> 
                         </> 
                     ) : ( 
                         <> 
                             <Document 
                                 renderMode="png" 
                                    className="p-3 border bg-light" 
                                    //file={host() + "/Publico/Documento/6446"} //local 
                                    file={host() + "/Publico/Documento/6558"} 
                                 onLoadSuccess={onDocumentLoadSuccess} 
                             > 
                                 <Page pageIndex={1} fixed pageNumber={pageNumber}></Page> 
                             </Document> 
                             <div className="justify-content-center d-flex btn-pages"> 
                                 <div style={{ marginTop: "10px" }}> 
                                     <button style={pageNumber === 1 ? { display: "none" } : {}} 
                                         onClick={() => setPageNumber(pageNumber - 1)} 
                                         className="btn-outlined btn-sm btn-page" 
                                     > 
                                         <i className="bi bi-arrow-left"></i> 
                                     </button> 
                                     {pageNumber} / {numPages}{" "} 
                                     <button style={pageNumber === numPages ? { display: "none" } : {}} 
                                         onClick={() => setPageNumber(pageNumber + 1)} 
                                         className="btn-outlined btn-sm btn-page" 
                                     > 
                                         <i className="bi bi-arrow-right"></i> 
                                     </button> 
                                 </div> 
                             </div> 
                         </> 
                     )} 
                </div> 

            </div>
            <div className="text-center"> <a href={host() + "/Publico/Documento/6558"}>Descarga Revista en pdf</a></div>
            <div className="flex-container">
          <Documentos number="FA-0276"></Documentos>
        </div>

                
           
      </div>
    );
  
}
